import { APP_NAME } from './constants'

interface SetCookieBase {
	key: string
	value: string
	path?: string
}

interface SetCookieWithDays extends SetCookieBase {
	daysToExpire: number
	expiresIn?: never
}

interface SetCookieWithExpiresIn extends SetCookieBase {
	daysToExpire?: never
	expiresIn: number
}

type SetCookieProps = SetCookieWithDays | SetCookieWithExpiresIn

export default class CookieStorage {
	public appName = APP_NAME

	private getKey(key: string) {
		return `${this.appName}/${key}`
	}

	public get(key: string): string | null {
		const name = `${this.getKey(key)}=`
		const cookies = document.cookie.split('; ')

		const cookie = cookies.find((savedCookie) => savedCookie.startsWith(name))

		return cookie ? decodeURIComponent(cookie.substring(name.length)) : null
	}

	public set({
		key,
		value,
		daysToExpire,
		expiresIn,
		path = '/',
	}: SetCookieProps) {
		const expires = new Date()
		const expiresDate = daysToExpire
			? daysToExpire * 24 * 60 * 60 * 1000
			: expiresIn ?? 0

		expires.setTime(expires.getTime() + expiresDate)
		document.cookie = `${this.getKey(key)}=${encodeURIComponent(
			value
		)}; expires=${expires.toUTCString()}; path=${path}`
	}

	public remove(key: string, path?: '/') {
		const pastDate = new Date(0).toUTCString()

		document.cookie = `${this.getKey(key)}=; expires=${pastDate}; path=${path};`
	}
}
