import { FeedbackMessage } from '@models/feedbackMessage.model'

const successes = {
	createdFilter: {
		title: 'Filtro salvo com sucesso!',
		message: 'Seu filtro foi salvo',
	},
	deletedFilter: {
		title: 'Filtro excluído com sucesso!',
		message: 'Seu filtro foi excluído',
	},
	editedFilter: {
		title: 'Filtro editado com sucesso!',
		message: 'Seu filtro foi editado',
	},
	moveFilter: {
		title: 'Filtro movido com sucesso!',
		message: 'Seu filtro foi movido para a pasta',
	},
	createdFolder: {
		title: 'Pasta salva com sucesso!',
		message: 'Sua pasta foi salva',
	},
	deletedFolder: {
		title: 'Pasta excluída com sucesso!',
		message: 'Sua pasta foi excluída',
	},
	editedFolder: {
		title: 'Pasta editada com sucesso!',
		message: 'Sua pasta foi editada',
	},
	createdNotebook: {
		title: 'Caderno salvo com sucesso!',
		message: 'Seu caderno foi salvo',
	},
	addNotebook: {
		title: 'Caderno adicionado com sucesso!',
		message: 'Seu caderno foi adicionado',
	},
	deletedNotebook: {
		title: 'Caderno excluído com sucesso!',
		message: 'Seu caderno foi excluído',
	},
	editedNotebook: {
		title: 'Caderno editado com sucesso!',
		message: 'Seu caderno foi editado',
	},
	moveNotebook: {
		title: 'Caderno movido com sucesso!',
		message: 'Seu carderno foi movido para a pasta',
	},
	updatedTest: {
		title: 'Teste editada',
		message: 'Seu Teste foi editado',
	},
	duplicatedTest: {
		title: 'Teste duplicada',
		message: 'Seu Teste foi duplicado',
	},
	deleteTest: {
		title: 'Teste removida',
		message: 'Seu Teste foi removido',
	},
	updatedPassword: {
		title: 'Senha editada',
		message: 'Sua senha foi editada',
	},
	updatedInfo: {
		title: 'Informações editadas',
		message: 'Suas informações pessoais foram editadas',
	},
	updatedAddress: {
		title: 'Endereço editado',
		message: 'Seu endereço foi editado',
	},
	deletedComment: {
		title: 'Comentário excluído com sucesso!',
		message: 'Seu comentário foi excluído',
	},
	createComment: {
		title: 'Comentário criado com sucesso!',
		message: 'Seu comentário foi criado',
	},
	deletedReminder: {
		title: 'Lembrete excluído com sucesso!',
		message: 'Seu lembrete foi excluído',
	},
	questionToNotebook: {
		title: 'Questão adicionada com sucesso!',
		message: 'Seu questão foi adicionada',
	},
	editPhoto: {
		title: 'Foto atualizada com sucesso!',
		message: 'Sua foto foi atualizada',
	},
	updateRating: {
		title: 'Simulado avaliado!',
		message: 'Simulado avaliado',
	},
	createErrorContent: {
		title: 'Mensagem de erro enviada!',
		message: 'Mensagem de erro enviada com sucesso',
	},
	clearHistoric: {
		title: 'Sucesso!',
		message: 'Histórico limpo',
	},
	copyLink: {
		title: 'Link para compartilhar copiado!',
		message: 'Link para compartilhar copiado para área de transferência',
	},
	updatedPhone: {
		title: 'Pronto! Telefone atualizado.',
		message: 'Telefone atualizado.',
	},
	remiderUpdatePhone: {
		title: 'Lembraremos você em 5 dias.',
		message: 'Lembraremos você em 5 dias.',
	},
}

export const successMessages: FeedbackMessage<keyof typeof successes> = {
	...successes,
}

const errors = {
	login: {
		title: 'Ocorreu um erro',
		message:
			'Caso o erro persista, por favor entre em contato com a engenharia.',
		buttonLabel: 'Tentar novamente',
	},
}

export const errorMessages: FeedbackMessage<keyof typeof errors> = {
	...errors,
}
