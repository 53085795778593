import React from 'react'

import { Dismiss24Regular } from '@fluentui/react-icons'
import * as Dialog from '@radix-ui/react-dialog'
import { modalCreators } from '@store/ducks/modal'
import { useAppDispatch } from '@store/hooks'

import {
	DialogOverlay,
	DialogContent,
	DialogTitle,
	DialogDescription,
	DialogClose,
	ImgIcon,
	Image,
} from './style'

type PropsModal = {
	title: string
	desc?: string
	children: JSX.Element
	open: boolean
	onOpenChange?: (value: boolean | ((prevVar: boolean) => boolean)) => void
	onCloseFunction?: () => void
	maxWidth?: string
	variant?:
		| 'checkBlue'
		| 'closeRed'
		| 'checkBlue2'
		| 'checkYellow'
		| 'checkGreen'
		| 'freeLimitedIcon'
		| 'blackFridayImage'
		| 'freeSevenQuestionsIcon'
		| 'questionErrorReport'
	popup?: boolean
}

export function ModalBase({
	title,
	desc,
	children,
	open,
	variant,
	popup,
	onOpenChange,
	onCloseFunction,
	...props
}: PropsModal) {
	const dispatch = useAppDispatch()

	return (
		<Dialog.Root open={open} onOpenChange={onOpenChange}>
			<Dialog.Portal>
				<DialogOverlay />
				<DialogContent className={variant} popup={popup} style={{ ...props }}>
					<DialogClose asChild>
						<button
							type='button'
							onClick={() => {
								if (onCloseFunction) {
									onCloseFunction()
								}
								dispatch(modalCreators.closeModal())
							}}
						>
							{variant === 'blackFridayImage' ? (
								<div
									style={{
										background: '#ffffff',
										padding: 1,
										borderRadius: 4,
									}}
								>
									<Dismiss24Regular />
								</div>
							) : (
								<Dismiss24Regular />
							)}
						</button>
					</DialogClose>
					{variant && variant === 'checkBlue' && (
						<ImgIcon title={variant} name={variant} width={187} height={182} />
					)}

					{variant && variant === 'checkGreen' && (
						<ImgIcon title={variant} name={variant} width={142} height={142} />
					)}

					{variant &&
						variant !== 'checkBlue' &&
						variant !== 'checkGreen' &&
						variant !== 'blackFridayImage' &&
						variant !== 'freeLimitedIcon' &&
						variant !== 'freeSevenQuestionsIcon' &&
						variant !== 'questionErrorReport' && (
							<ImgIcon
								title={variant}
								name={variant}
								width={102}
								height={102}
							/>
						)}
					{variant && variant === 'freeLimitedIcon' && (
						<ImgIcon title={variant} name={variant} width={254} height={187} />
					)}
					{variant && variant === 'freeSevenQuestionsIcon' && (
						<ImgIcon title={variant} name={variant} width={254} height={187} />
					)}

					{variant && variant === 'blackFridayImage' && (
						<Image style={{ zIndex: -1 }}>
							<a
								style={{ display: 'flex', cursor: 'pointer' }}
								href='https://pay.hotmart.com/V25586317O?off=uxqiiqbp&checkoutMode=10&bid=1700823971723'
							>
								<img src='/assets/images/blackFriday.png' alt={title} />
							</a>
						</Image>
					)}

					{title && <DialogTitle>{title}</DialogTitle>}
					{desc && <DialogDescription>{desc}</DialogDescription>}
					{children}
				</DialogContent>
			</Dialog.Portal>
		</Dialog.Root>
	)
}
